import React from "react"
import DefaultActions from "./DefaultActions"
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  Create,
  BooleanField,
  BooleanInput,
  TextInput,
  DateField,
  FunctionField,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from "react-admin"
import DefaultPagination from "./DefaultPagination"
import TimestampField from "./TimestampField"

export function ContactList ({ ...props }) {
  return (
    <List
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='email' label='Email' sortable={false} />
        <TextField source='name' label='Name' sortable={false} />
        <TextField source='amount' label='Trucks Amount' sortable={false} />
        <TextField source='phone' label='Phone' sortable={false} />
        <BooleanField
          source='finishedRegistration'
          label="Finished Registration"
        />
        <TimestampField
          source='createdAt'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <FunctionField
          source="utmParams"
          label='Utm Source'
          render={record => {
            if (!record?.utmParams) {
              return ""
            }

            const utmSource = record?.utmParams.find(
              (utm: { source: string; value: any; }) => 
                utm.source === "utm_source",
            )

            return utmSource?.value
          }}
        />
      </Datagrid>
    </List>
  )
}

export function ContactEdit ({...props}) {
  function renderUtmParamsField (obj: any) {
    return (
      <ArrayInput source={"utmParams"} label="UTM Parameters">
        <SimpleFormIterator disableReordering>
          <FormDataConsumer>
            {({ getSource = () => {} }) => {
              return (
                <div>
                  <TextInput
                    label="Source"
                    source={getSource("source") || ""}
                    style={{
                      marginRight: "1rem",
                    }}
                  />
                  <TextInput
                    label="Value"
                    source={getSource("value") || ""}
                    style={{
                      marginRight: "1rem",
                    }}
                  />
                </div>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    )
  }

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='email' label='Email' sortable={false} />
        <TextInput source='name' label='Name' sortable={false} />
        <TextInput source='amount' label='Trucks Amount' sortable={false} />
        <TextInput source='phone' label='Phone' sortable={false} />
        <BooleanInput
          source='finishedRegistration'
          label="Finished Registration"
        />
        <DateField
          source='createdAt'
          locales='sv-SE'
          showTime
        />
        <DateField
          source='finishedRegistrationAt'
          locales='sv-SE'
          showTime
        />
        <FunctionField
          label=""
          source="utmParams"
          render={(obj: any) => {
            return <>
              <table>
                {renderUtmParamsField(obj)}
              </table>
            </>
          }}
        />
      </SimpleForm>
    </Edit>
  )
}

export function ContactCreate ({...props}) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='email' label='Email' sortable={false} />
        <TextInput source='name' label='Name' sortable={false} />
        <TextInput source='amount' label='Trucks Amount' sortable={false} />
        <TextInput source='phone' label='Phone' sortable={false} />
        <BooleanInput
          source='finishedRegistration'
          label="Finished Registration"
        />
      </SimpleForm>
    </Create>
  )
}

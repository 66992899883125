export const LawFirmOptions = [
  { id: "AILTS", name: "AILTS" },
  { id: "BRC", name: "BRC" },
  { id: "KAP", name: "KAP" },
  { id: "LEO", name: "LEO" },
  { id: "Von Rüden", name: "Von Rüden" },
  { id: "Hartung", name: "Hartung" },
  { id: "DPR", name: "Redell" },
  { id: "Karimi", name: "Karimi" },
  { id: "Ferox", name: "Ferox"},
  { id: "ABN", name: "ABN"},
  { id: "Gamesright", name: "Gamesright"},
  { id: "Placeholder", name: "Lawfirm Placeholder"},
]
